.form_search_wrap{
  width: 100%;
  //display: none;
  /*-ms-flex-align: stretch;
  -webkit-box-align: stretch;
  align-items: stretch;
  width: 100%;*/
  @include respond-to(ipad){
    position: relative;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    width: 50%;
  }
  @include respond-to(desktop){
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .search-panel{
    //display: none;
    @include respond-to(ipad){
      width: 50%;
      display: -ms-flexbox;
      display: -webkit-box;
      display: flex;
    }
  }
  &_h{
    @include respond-to(desktop){
      padding-top: 5px;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }
  }
}
.cart_drop_wrap{
  display: none;
  position: absolute;
  width: 100%;
  max-width: 350px;
  top: 100%;
  right: 0;
  -webkit-box-shadow: 0px 10px 18px 2px  rgba(221,235,243,1);
  -moz-box-shadow: 0px 10px 18px 2px  rgba(221,235,243,1);
  box-shadow: 0px 10px 18px 2px  rgba(221,235,243,1);
  z-index: 11;
  @include respond-to(ipad){
    width: 10000%;
  }
  .cash{
    color: #0a537b;
    font-weight: 600;
  }
  .title{
    padding: 15px 25px 10px;
    background-color: #093954;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
  }
  .cart_list_wrap{
    padding: 15px 25px 40px;
    background-color: #ebf1f6;
    font-size: 12px;
    .cart_list{
      font-size: 12px;
      &_item{
        color: #000;
        padding: 0;
        margin: 0 0 2px;
        &:last-child{
          margin: 0 0 10px;
        }
        .item_inner{
          position: relative;
          padding: 15px;
          background-color: #fff;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
          .img{
            width: 25%;
          }
          .desc{
            width: 75%;
            padding: 0 10px;
            .name{
              margin: 0 0 5px;
              color: #23527c;
              a{
                color: #23527c;
              }
            }
            .price{
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: start;
              -ms-flex-align: start;
              align-items: flex-start;
              .info{
                width: 50%;
                span{
                  display: block;
                }
              }
              .count{
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                width: 50%;
                button{
                  cursor: pointer;
                  max-width: 100%;
                  background-color: #92abb9;
                  width: 16px;
                  height: 16px;
                  border: none;
                  border-radius: 50%;
                  outline: none;
                  &:hover{
                    background-color: #ced9df;
                  }
                  img{
                    margin: 2px;
                    -o-object-fit: cover;
                    object-fit: cover;
                  }
                  &.remove{
                    -webkit-transform: rotate(90deg);
                    -moz-transform: rotate(90deg);
                    -ms-transform: rotate(90deg);
                    -o-transform: rotate(90deg);
                    transform: rotate(90deg);
                  }
                  &.add{
                    -webkit-transform: rotate(-90deg);
                    -moz-transform: rotate(-90deg);
                    -ms-transform: rotate(-90deg);
                    -o-transform: rotate(-90deg);
                    transform: rotate(-90deg);
                  }
                }
                input{
                  max-width: 100%;
                  width: 33px;
                  height: 14px;
                  margin: 0 5px;
                  border-radius: 7px;
                  border: 1px solid #e0e9ed;
                  background-color: #f4f8fa;
                  color: #87898b;
                  font-family: Verdana, sans-serif;
                  text-align: center;
                  outline: none;
                }
              }
            }
          }
          .remove_product{
            position: absolute;
            top: 10px;
            right: 10px;
            color: #9cbaca;
            font-size: 26px;
            line-height: 1;
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
      }
    }
    .price_total{
      color: #000;
      text-align: right;
      .cash{
        color: #0a537b;
        font-weight: 600;
      }
    }
    .checkout_wrap{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 5px 0;
      .shopping_l{
        font-size: 11px;
        text-transform: uppercase;
        color: #056ea9;
        border-bottom: 1px solid transparent;
        -webkit-transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -ms-transition: all 200ms ease-in-out;
        -o-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;
        &:hover{
          border-bottom: 1px solid #056ea9;
        }
      }
      .checkout_l{
        font-size: 11px;
        display: inline-block;
        width: 100%;
        max-width: 150px;
        text-transform: uppercase;
        text-align: center;
        border-radius: 20px;
        padding: 10px 15px;
        border: 1px solid transparent;
        color: #e7f1f7;
        background-color: #fbd44d;
        /*box-shadow: 0px 2px 0px 0px #e2b620;*/
        transition: all 200ms ease-in-out;

        border: none;
        border-radius: 17px;
        box-shadow: inset 0 -2px 0 #e2b620;
        background: linear-gradient(#fbce34, #fcd85e, #fbce34);
        font-family: Verdana, sans-serif;
        //text-transform: uppercase;
        outline: none;
        color: #fff;
        &:hover {
          box-shadow: inset 0 -3px 0 #e2b620;
          background: linear-gradient(#fcd85e, #fbce34, #fcd85e);
        }
        &:hover{
          /*border: 1px solid #fbd44d;
          color: #fbd44d;
          background-color: transparent;*/
        }
      }
    }
  }
  &.opened{
    display: block;
  }
}