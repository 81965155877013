/******************************************************/
/******************* Media styles *********************/
/******************************************************/
@media screen and (max-width: 1170px) {
  .services-info .item .text {font-size: 12pt;}
  .services-info .item .icon {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }

  .main .products .banners .line {height: 180px;}

  .brands .container a:nth-of-type(7n),
  .brands .container a:nth-of-type(7n) ~ a {order: 1;}
}
@media screen and (max-width: 1070px) {
  .header .purchase-info .icon {margin-left: 5px;}
  .header .purchase-info .item {margin: 3px;}

  .services-info .item .text {font-size: 11pt;}

  .main .products .banners .line {height: 160px;}

  .menu .menu-items .item {font-size: 9pt;}
  .menu .offers .text {font-size: 9pt;}
  .menu .delivery-opts {font-size: 9pt;}
}
@media screen and (max-width: 980px) {
  .services-info .item {height: 48px;}
  .services-info .item .text {font-size: 10pt;}

  .checkout-steps .step.login .content {flex-direction: column;}

  .main .products .banners .line {height: 140px;}

  .menu .menu-items .item {font-size: 8pt;}
  .menu .delivery-opts {
    width: 180px;
    font-size: 8pt;
  }
}
@media screen and (max-width: 925px) {
  .head-panel .user .text {
    display: none;
  }
  .head-panel .user .icon {
    width: 18px;
    height: 18px;
  }

  .header .container {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .header .search-panel {margin: 5px 0;}
  //.header .purchase-info .icon {margin-left: 5px;}
  .header .purchase-info .item {margin: 3px;}

  .main .categories .level-1 {font-size: 10pt;}
  .main .categories .level-2 {font-size: 9pt;}

  .main .products .banners .line {height: 120px;}

  .main .products .info-box {margin: 30px 0;}
  .main .products .info-box .text {font-size: 11pt;}
}
@media screen and (max-width: 850px) {
  .menu .menu-icon {
    display: block;
  }
  .menu .container {
    flex-wrap: wrap;
  }
  .menu .container::after {
    content: '';
    width: 100%;
    order: 0;
  }
  .menu .menu-items {order: 1;}
  .menu .menu-items .item {padding: 0;}
  .menu input[type=checkbox]:checked ~ .menu-items {display: none;}

  .carts .main {flex-direction: column-reverse;}
  .carts .purchase-info {padding: 30px 0;}
  .carts .products {margin: 0;}
  .carts .purchase-info .total {width: 280px;}
  .carts .purchase-info .continue {width: 240px;}

  .checkout .main {flex-direction: column-reverse;}
  .checkout-steps .step.login .content {flex-direction: row;}
  .checkout .order-info {
    margin: 0 auto;
  }
}
@media screen and (max-width: 730px) {
  .head-panel .contacts .text {
    //display: none;
  }
  .head-panel .contacts .icon {
    width: 18px;
    height: 18px;
  }

  .services-info .item {padding: 0 10px;}
  .services-info .item .icon {margin-right: 8px;}

  .footer .categories {width: 75%;}
  .footer .information {
    width: 25%;
    flex-direction: column;
  }
  .footer .information .logo {width: 100%;}
  .footer .contacts .item .text {font-size: 7pt;}
}
@media screen and (max-width: 600px) {
  .head-panel .show-message {
    width: auto;
    padding: 3px 10px;
    left: 0;
  }

  .brands .container a:nth-of-type(5n),
  .brands .container a:nth-of-type(5n) ~ a {order: 1;}

  .menu .menu-items {
    flex-direction: column;
    height: auto;
  }
  .menu .menu-items .item {
    height: 30px;
    padding-left: 15px;
  }

  .services-info {flex-direction: column;}
  .services-info .item {margin: 10px 0;}
  .services-info .item .text {font-size: 12pt;}

  .main {flex-direction: column;}
  .main .categories .level-1,
  .main .categories .level-2 {font-size: 12pt;}
  .main .products .banners .line {height: 100px;}

  .carts .products .products-box .product .quantity {flex-direction: column-reverse;}
  .carts .products .products-box .product .quantity .add {transform: rotate(180deg);}
  .carts .products .products-box .product .quantity .remove {transform: rotate(0deg);}
  .carts .products .products-box .product .quantity input {margin: 3px;}
  .carts .products .header .image,
  .carts .products .products-box .product .image {display: none;}

  .checkout-steps .step.login .content {flex-direction: column;}

  .footer .container {flex-direction: column;}
  .footer .categories .column {width: auto;}
  .footer .container > div {width: auto;}
  .footer  .information {
    flex-direction: row;
    margin-top: 30px;
  }
}
@media screen and (max-width: 420px) {
  .container {width: 100%;}
  .head-panel .container,
  .header .container {justify-content: space-around;}

  .head-panel .languages .arrow {
    //display: none;
  }
  .head-panel .languages {padding: 0 10px;}
  .head-panel .contacts {justify-content: space-around;}
  .head-panel .user {justify-content: space-around;}

  .header .container .purchase-info .items {flex-direction: column;}
  .header .container .search-panel {margin: 10px;}
  .header .container .purchase-info .icon {
    //width: 30px;
    //height: 30px;
    //margin: 10px;
  }

  .cart .bread-crumbs {margin-left: 10px;}
  .cart .title {margin-left: 10px;}

  .checkout .bread-crumbs {margin-left: 10px;}
  .checkout .title {margin-left: 10px;}
  .checkout-steps .step.review .cart-total {width: 95%;}
  .checkout .order-info {
    width: 90%;
    padding: 10px;
  }

  .menu .menu-icon {margin-left: 5px;}
  .menu .delivery-opts {width: 150px;}

  .services-info .item {margin: 10px;}
  .services-info .item .text {font-size: 10pt;}

  .main .products .info-box .text {font-size: 8pt;}

  .carts .container {padding: 0 10px;}
  .carts .products {margin: 0;}
  .carts .products .products-box .product .product-name,
  .carts .products .products-box .product .price,
  .carts .products .products-box .product .total {font-size: 8pt;}

  .checkout .checkout-steps {margin: 0 10px;}

  .footer .categories {justify-content: space-around;}
  .footer .information {
    flex-direction: column;
    align-items: center;
  }
  .footer .information .contacts .item {margin: 10px 0;}
  .footer .information .contacts .item .text{font-size: 10pt;}

  .footer-panel .container {justify-content: center;}
  .footer-panel .container .copyright {font-size: 8pt;}
}