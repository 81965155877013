@CHARSET "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,
form,fieldset,input,textarea,p,blockquote,th,td {
  padding: 0;
  margin: 0;
}
html{
  height: 100%;
}
*{
  box-sizing: border-box;
}
a{
  text-decoration:none;
}
table {
  border-spacing: 0;
}
fieldset,img {
  border: 0;
}
strong, b{
  font-weight: 700;
}
ol,ul {
  list-style: none;
  margin:0;
  padding:0;
  li{
    min-height: 1px;
    height: auto;
  }
}
caption,th {
  text-align: left;

}
h1,h2,h3,h4,h5,h6 {
  font-size: 100%;
  margin:0;
  padding:0;
}
q:before,q:after {
  content:'';
}
abbr,acronym {
  border: 0;
}
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  &:focus{
    outline: none;
  }
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}
/* Custom CSS
 * --------------------------------------- */
body{
  line-height: 1.5;
  font-weight: 400;
  font-size: 1.4rem;
  height: 100%;
  font-family: Verdana, sans-serif;
  strong, b{
    font-weight: 700;
  }
}
.scroll_y{
  overflow-y: scroll;
}
.wrapper{
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  min-height: 100%;

  //display: table;
  //height: 100%;
  .container{
    padding-right: 15px;
    padding-left: 15px;
    }
}
.container{
  position: relative;
  margin: 0 auto;
  max-width: 1170px;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  &:before{
    content: '';
    display: table;
  }
  &:after{
    content: '';
    display: table;
    clear: both;
  }
}
.row{
  margin: 0 -15px;
  &:before{
    content: '';
    display: table;
  }
  &:after{
    content: '';
    display: table;
    clear: both;
  }
}
aside{
  p{
    font-size: 14px;
    color: #212e40;
    padding: 15px;
    text-align: center;
    @include respond-to(ipad){
      text-align: left;
    }
  }
}
.main{}
img{
  max-width: 100%;
}
p{
  font-size: 1.4rem;
}
h1, h2, h3, h4, h5, h6{
  color: #000;
  font-weight: 500;
  line-height: 1.2;
}
// helpers

.text_a_left{
  text-align: center;
  @include respond-to(ipad){
    text-align: left;
  }
}
.text_a_right{
  text-align: center;
  @include respond-to(ipad){
    text-align: right;
  }
}
.text_a_center{
  text-align: center;
}
a{
  color: #d4e3ec;
}

.clearfix{
  position: relative;
  &:before{
    content: '';
    display: table;
  }
  &:after{
    content: '';
    display: table;
    clear: both;
  }
}

#to_top{
  display: none;
  position: fixed;
  bottom: 15px;
  right: 15px;
  line-height: 1;
  z-index: 999;
  padding: 6px 13px;
  background: rgba(255,255,255,.75);
  margin: auto;
  align-items: center;
  i{
    font-size: 4rem;
    color:  rgba(0,145,126,.85);
    background: none;
    line-height: 1;
  }
}
//////////////


/******************** services-info ********************/
.services-info {
  padding: 20px 0;
  .item_wrap {
    padding: 15px;
    min-height: 1px;
    @include respond-to(ipad){
      width: 25%;
      float: left;
      padding: 0 15px;
    }
    .item{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      //height: 60px;
      //border-radius: 30px;
      //box-shadow: 0 0 50px #006da80f;
      padding: 0 15px;
      margin: 0!important;
      .icon {
        height: 32px;
        width: 37px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        img {
          width: 100%;
        }
        svg{
          width: 100%;
          path{
            fill: #000;
          }
        }
      }
      .text {
        color: #3b3d43;
        font-family: Verdana, sans-serif;
        font-size: 13px;
        @include respond-to(ipad){
          font-size: 14px;
        }
        @include respond-to(desktop){
          font-size: 16px;
        }
      }
    }
  }
}
/********************** categories *********************/
.main{
  .categories {
    margin-bottom: 40px;
    display: block;
    max-width: 100%;
    min-width: 0;
    min-height: 1px;
    padding: 15px;
    @include respond-to(ipad){
      float: left;
      padding: 0 15px;
      width: 25%;
    }
    .title {
      color: #000;
      font-family: Verdana, sans-serif;
      font-size: 15pt;
      font-weight: normal;
      margin: 20px 0;
      padding-left: 15px;
    }
    .accordion-menu{
      input[type=checkbox]{
        position: absolute;
        opacity: 0;
        &:checked + label + ul{
          display: block;
        }
        &:checked + label:nth-of-type(n) + ul {
          display: block;
        }
      }
      > li:not(:last-child) {
        margin-bottom: 5px;
      }
      ul {
        display: none;
      }
    }
    .level-1{
      > .has-children {
        background-color: #fbfcfd;
        border-radius: 20px;
        > ul {
          background-color: #fbfcfd;
          position: relative;
        }
      }
      ul::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 30px;
        background-color: #fbfcfd;
      }
    }
    .level-1 .special > label,
    .level-1 .special > a{
      //background-color: #d51e38;
      background-color: #0a537b;
    }
  }
  .products{
    display: block;
    width: 100%;
    min-height: 1px;
    padding: 15px;
    @include respond-to(ipad){
      width: 75%;
      padding: 0 15px;
      float: left;
    }
  }
}



.main .categories .level-1 > li > label,
.main .categories .level-1 > li > a {
  height: 40px;
  //background-color: #0a537b;
  background-color: #3b3d43;
  border-radius: 20px;
  padding-left: 15px;
}
.main .categories .level-1 > li > label .icon {
  width: 16px;
  height: 15px;
  position: absolute;
  top: calc(50% - 8px);
  right: 15px;
  background-image: url('../img/icons/down-arrow.svg');
  background-size: cover;
  transform: rotate(-90deg);
}
.main .categories .accordion-menu input[type=checkbox]:checked ~ label .icon {
  transform: rotate(0deg);
}
.main .categories .level-1 {
  font-size: 11pt;
  text-transform: uppercase;
  font-family: Verdana, sans-serif;
}
.main .categories .level-1 label,
.main .categories .level-1 a {
  display: flex;
  align-items: center;
  position: relative;
  color: #fff;
}
.main .categories .level-1 label:hover,
.main .categories .level-1 a:hover {cursor: pointer;}
.main .categories .level-2 {text-transform: none;}
.main .categories .level-2 label,
.main .categories .level-2 li label a {}
.main .categories .level-2 li a {
  height: 30px;
  padding-left: 15px;
  color: #3b3d43;
}
.main .categories .level-2 li.has-children a,
.main .categories .level-2 li.has-children > label{
  //color: #0a537b;
  color: #3b3d43;
  font-weight: 400;
  padding-left: 0;
}
.main .categories .level-2 li.has-children a{
  padding-left: 15px;
}
.main .categories .level-2 > li > label {position: relative;}
.main .categories .level-2 > li > label span {margin-left: 5px;}
.main .categories .level-2 > li > label span::before {content: "(";}
.main .categories .level-2 > li > label span::after  {content: ")";}
.main .categories .level-2 > li > label > .icon {
  position: absolute;
  top: calc(50% - 8px);
  right: 15px;
  width: 16px;
  height: 16px;
  background-image: url('../img/icons/plus.svg');
  background-size: cover;
}
.main .categories .level-2 input[type=checkbox]:checked ~ label {
  background-color: #fff;
}
.main .categories .level-2 input[type=checkbox]:checked ~ label .icon {
  background-image: url('../img/icons/minus.svg');
}
.main .categories .level-2 ul {background-color: #fff;}
.main .categories .level-3 label,
.main .categories .level-3 a {
  height: 24px;
  padding-left: 15px;
  color: #006ca8;
}
.main .categories .level-2 .level-3 li a,
.main .categories .level-2 .level-3 li label{
  color: #006ca8;
  font-size: 13px;
}

/*********************** products **********************/

.main .products .banners .line {
  height: 200px;
  display: flex;
  margin-bottom: 20px;
}
.main .products .banners .line .item {
  flex: 1;
  height: 100%;
  //box-shadow: 0 0 50px #006da80f;
  background-color: #fff;
}
.main .products .banners .line .item:not(:last-child) {margin-right: 10px;}
.main .products .banners .line .item img {
  width: 100%;
  height: 100%;
  //object-fit: cover;
}
.products-box {
  //display: flex;
  //flex-wrap: wrap;
  //justify-content: center;
  margin-top: 30px;
  margin-bottom: 60px;
}
.main .products .products-box > .title {
  width: 100%;
  margin-bottom: 30px;
  color: #1d1f23;
  text-transform: uppercase;
  font-family: Verdana, sans-serif;
  font-size: 14pt;
  display: flex;
  justify-content: stretch;
  align-items: center;
  position: relative;
}
.products-box > .title .text {
  background-color: #fff;
  letter-spacing: 3px;
  text-align: center;
  width: 100%;
  padding: 0;
  @include respond-to(ipad){
    padding: 0 20px 0 0;
    text-align: left;
    width: auto;
  }
}
.products-box > .title .line {
  display: none;
  @include respond-to(ipad){
    display: block;
    width: 90%;
    height: 0;
    border: 2px solid #f4f6f8;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: -1;
  }
}
.products-box > .text {
  color: #737476;
  font-family: Verdana, sans-serif;;
  font-size: 11pt;
  text-align: center;
  @include respond-to(ipad){
    padding-right: 40px;
    text-align: left;
  }
}
.products-box > .text p {
  margin-bottom: 30px;
  line-height: 24px;
}
.products-box .item {
  //padding: 40px 20px 15px 25px;
  padding: 2px 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-color: #fff;
  //box-shadow: 0 0 50px #006da80f;
  position: relative;
  border-left: 1px solid #f4f6f8;
}
.products-box .item .label {
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 0;
  top: 0;
  left: 3px;
  white-space: normal;
  img{
    margin-bottom: 2px;
  }
}
.products-box .item .label img {
  width: 100%;
}
.products-box .item .title {
  text-align: left;
  font-family: Verdana, sans-serif;
  font-size: 12px;
  margin: 0 0 30px 0;
  a{
    font-family: Verdana, sans-serif;
    font-size: 12px;
    color: #000;
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    &:hover{
      color: #006cac;
    }
  }
}
.products-box .item .image {
  width: 100%;
  //height: 85px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 10px 0;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  &:hover{
    //opacity: .75;
  }
}
.products-box .item .image img {height: 100%;}
.products-box .item .price {
  color: #1d1f23;
  font-family: Verdana, sans-serif;
  font-size: 10pt;
  margin-bottom: 10px;
}
.products-box .item .price span {
  font-weight: bold;
  color: #0a537b;
}
.products-box{
  .item{
    .count{
      position: absolute;
      right: 5px;
      bottom: 0;
      z-index: 2;
      .icon_count{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background-color: #fbce34;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        background-image: url('../img/shop_cart.png');
        background-repeat: no-repeat !important;
        background-position: 50% 50%;
        img{
          opacity: 0;
          display: none;
          max-width: 100%;
        }
        &:hover{
          + .icon_show{
            //display: block;
          }
        }
      }
      .icon_show{
        bottom: 0;
        height: auto;
        position: absolute;
        display: none;
        background: #fff;
        padding: 5px;
        right: -5px;
        border-radius: 25px;
        -webkit-box-shadow: 0px 0px 21px 14px rgba(248,248,248,1);
        -moz-box-shadow: 0px 0px 21px 14px rgba(248,248,248,1);
        box-shadow: 0px 0px 21px 14px rgba(248,248,248,1);
        &.active{
          display: block;
        }
      }
      &:hover{
        .icon_show{
          display: block;
        }
      }
    }
  }
}
.main{
  .products{
    .products-box{
      .item{
        .count{
          position: absolute;
          right: 5px;
          bottom: 0;
          z-index: 2;
          .icon_count{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            background-color: #fbce34;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            cursor: pointer;
            position: relative;
            background-image: url('../img/shop_cart.png');
            background-repeat: no-repeat !important;
            background-position: 50% 50%;
            img{
              opacity: 0;
              display: none;
              max-width: 100%;
            }
            &:hover{
              + .icon_show{
                  //display: block;
              }
            }
          }
          .icon_show{
            bottom: 0;
            height: auto;
            position: absolute;
            display: none;
            background: #fff;
            padding: 5px;
            right: -5px;
            border-radius: 25px;
            -webkit-box-shadow: 0px 0px 21px 14px rgba(248,248,248,1);
            -moz-box-shadow: 0px 0px 21px 14px rgba(248,248,248,1);
            box-shadow: 0px 0px 21px 14px rgba(248,248,248,1);
            &.active{
              display: block;
            }
          }
          &:hover{
            .icon_show{
              //display: block;
            }
          }
        }
      }
    }
  }
}
.products-box{
  .item{
    .count{
      position: absolute;
      right: 5px;
      bottom: 0;
      z-index: 2;
      .icon_count{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background-color: #fbce34;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        background-image: url('../img/shop_cart.png');
        background-repeat: no-repeat !important;
        background-position: 50% 50%;
        img{
          opacity: 0;
          display: none;
          max-width: 100%;
        }
        &:hover{
          + .icon_show{
            //display: block;
          }
        }
      }
      .icon_show{
        bottom: 0;
        height: auto;
        position: absolute;
        display: none;
        background: #fff;
        padding: 5px;
        right: -5px;
        border-radius: 25px;
        -webkit-box-shadow: 0px 0px 21px 14px rgba(248,248,248,1);
        -moz-box-shadow: 0px 0px 21px 14px rgba(248,248,248,1);
        box-shadow: 0px 0px 21px 14px rgba(248,248,248,1);
        &.active{
          display: block;
        }
      }
      &:hover{
        .icon_show{
          //display: block;
        }
      }
    }
  }
}
.products-box .item .count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}
.carts .products .products-box .product .quantity button,
.products-box .item .count button {
  width: 16px;
  height: 16px;
  background-color: #ced9df;
  border: none;
  border-radius: 50%;
  outline: none;
}
.carts .products .products-box .product .quantity button:hover,
.products-box .item .count button:hover {
  cursor: pointer;
}
.carts .products .products-box .product .quantity button img,
.products-box .item .count button img {
  //object-fit: cover;
  //margin: 2px;
  display: none;
}
.carts .products .products-box .product .quantity .remove,
.products-box .item .count .remove {
  position: relative;
  background-color: #0a537b;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  //transform: rotate(90deg);
}
.carts .products .products-box .product .quantity .add,
.products-box .item .count .add {
  position: relative;
  background-color: #0a537b;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  //transform:  rotate(-90deg);
}

.carts .products .products-box .product .quantity .remove:before,
.products-box .item .count .remove:before{
  content: '';
  width: 20px;
  height: 1px;
  background-color: #fff;
}

.carts .products .products-box .product .quantity .add:before,
.products-box .item .count .add:before{
  content: '';
  width: 20px;
  height: 1px;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.carts .products .products-box .product .quantity .add:after,
.products-box .item .count .add:after{
  content: '';
  width: 20px;
  top: 50%;
  left: 50%;
  height: 1px;
  background-color: #fff;
  -webkit-transform: rotate(90deg) translate(0, -50%);
  -moz-transform: rotate(90deg) translate(0, -50%);
  -ms-transform: rotate(90deg) translate(0, -50%);
  -o-transform: rotate(90deg) translate(0, -50%);
  transform: rotate(90deg) translate(0, -50%);
}



.carts .products .products-box .product .quantity button.active,
.products-box .item .count button.active {
  //background-color: #92abb9;
  background-color: #0a537b;
}
.carts .products .products-box .product .quantity input,
.products-box .item .count input {
  //width: 33px;
  //height: 14px;
  margin: 5px auto;
  //border-radius: 7px;
  border: 1px solid #e0e9ed;
  background-color: #f4f8fa;
  color: #87898b;
  font-family: Verdana, sans-serif;
  text-align: center;
  outline: none;

  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.carts .products .products-box .product .quantity input::-webkit-outer-spin-button,
.carts .products .products-box .product .quantity input::-webkit-inner-spin-button,
.products-box .item .count input::-webkit-outer-spin-button,
.products-box .item .count input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.products-box .item .buy,
.carts .purchase-info .continue,
.carts .purchase-info .total .checkout {
  height: 36px;
  width: 150px;
  border: none;
  border-radius: 17px;
  box-shadow: inset 0 -2px 0 #e2b620;
  background: linear-gradient(#fbce34, #fcd85e, #fbce34);
  color: #0a537b;
  font-family: Verdana, sans-serif;
  text-transform: uppercase;
  outline: none;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  &:hover{
    box-shadow: inset 0 -3px 0 #e2b620;
    background: linear-gradient(#fcd85e, #fbce34, #fcd85e);
  }
}
.carts .purchase-info .continue {
  width: 80%;
  margin: 10px 0;
  background: #056ea9;
  box-shadow: none;
  color: #fff;
}
.products-box .item .buy:hover,
.carts .purchase-info .continue:hover,
.carts .purchase-info .total .checkout:hover {cursor: pointer;}
.products-box .item .buy:active,
.cart .purchase-info .continue:active,
.carts .purchase-info .total .checkout:active {box-shadow: inset 0 2px 0 #e2b620;}
.info-box {
  background-color: #f9f9f9;
  padding: 15px 30px;
  color: #3b3d43;
  overflow: hidden;
  /*height: 70px;
  margin: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;*/
}
.main .products .info-box .icon {
  width: 70px;
  height: 70px;
  margin: 0 auto;
  @include respond-to(ipad){
    margin-right: 20px;
    float: left;
  }
}
.main .products .info-box .icon img { height: 100%;}
.main .products .info-box .text {
  text-align: center;
  padding-top: 20px;
  line-height: 1.2;
  text-transform: uppercase;
  font-family: Verdana, sans-serif;
  font-size: 15px;
  letter-spacing: 1px;
  color: #3b3d43;
  //color: #006cac;
  @include respond-to(ipad){
    text-align: left;

  }
  a{
    color: #006cac;
    font-weight: 600;
  }
}


/******************************************************/
/*                       Carts                        */
/******************************************************/
/******************** bread-crumbs ********************/
.bread-crumbs {
  margin: 25px 0;
  display: flex;
  justify-content: flex-start;
}
.bread-crumbs .item {
  font-size: 10pt;
  font-family: Verdana, sans-serif;
  color: #9e9e9e;
}
.bread-crumbs .item:not(:last-child) {
  border-right: 1px solid #1d1f23;
  margin-right: 20px;
  padding-right: 20px;
  color: #1d1f23;
}

/************************ title ***********************/
.checkout .title,
.carts .title {
  margin: 35px 0;
  font-family: Verdana, sans-serif;
  font-size: 20pt;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 10px;
  color: #1d1f23;
}

/************************* main ***********************/
.checkout .main,
.carts .main {
  flex-direction: row-reverse;
  margin-bottom: 20px;
}

/********************** order-info ********************/
.checkout .order-info {
  width: 340px;
  height: auto;
  padding: 20px;
  margin-left: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 50px #006da80f;
}
.checkout .order-info .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Verdana, sans-serif;
  font-size: 14pt;
}
.checkout .order-info .product {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #dae5eb;
  display: flex;
}
.checkout .order-info .product .image {
  width: 100px;
  margin-right: 15px;
  border: 1px solid #f1f7f9;
  display: flex;
  align-items: center;
}
.checkout .order-info .product .image img {width: 100%;}
.checkout .order-info .product .description {
  padding: 5px 0;
  font-family: Verdana, sans-serif;
  font-size: 11pt;
}
.checkout .order-info .product .description .info {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}
.checkout .order-info .product .description .info .price span {
  font-weight: bold;
  color: #0a537b;
  margin-right: 5px;
}
.checkout .order-info .product .description .info .count {color: #929297;}
.checkout .order-info .product .description .info .count::before {content: 'x';}
.checkout .order-info .product .delete {width: 35px;}
.checkout .order-info .product .delete img:hover {cursor: pointer;}
.checkout .order-info .product .delete img {width: 100%;}
.checkout .order-info .total {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-family: Verdana, sans-serif;
  font-size: 11pt;
}
.checkout .order-info .total span {
  font-weight: bold;
  font-size: 12pt;
  color: #0a537b;
  margin-left: 5px;
}

/******************** checkout-steps ******************/
.checkout-steps {
  flex: 2;
  display: flex;
  flex-direction: column;
}
.checkout-steps .step {
  margin-bottom: 30px;
  display: block;
}
.checkout-steps .step.hidden > :not(.header) {display: none}
.checkout-steps .step.hidden .header {
  color: #849ca9;
  margin: 0;
}
.checkout-steps .step > .header {
  margin-bottom: 35px;
  font-family: Verdana, sans-serif;
  font-size: 16pt;
  color: #093954;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.checkout-steps .step .header .number {
  margin-right: 20px;
  font-size: 17pt;
}
.checkout-steps .step .description {
  margin-left: 40px;
  margin-bottom: 35px;
  font-family: Verdana, sans-serif;
  font-size: 11pt;
}
.checkout-steps .step.login .content {
  display: flex;
  justify-content: space-between;
}
.checkout-steps .step.login .content form {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
.checkout-steps .step.login .content form .header {
  margin-bottom: 15px;
  font-size: 13pt;
  font-weight: bold;
  font-family: Verdana, sans-serif;
  color: #313138;
  display: flex;
  justify-content: flex-start;
}
.checkout-steps .step.login .content form label {
  font-family: Verdana, sans-serif;
  font-size: 11pt;
  margin: 15px;
}
.checkout-steps .step.login .content form label::after {content: '*';}
.checkout-steps .step.login .content form input {
  max-width: 300px;
  height: 35px;
  padding-left: 10px;
  border: 1px solid #7f7f7f;
  color: #2c2c2c;
  font-family: Verdana, sans-serif;
}
.checkout-steps .step.login .content form .forgot-password {
  margin-top: 10px;
  margin-left: 20px;
  position: relative;
  font-family: Verdana, sans-serif;
  font-size: 9pt;
  color: #a20f10;
}
.checkout-steps .step.login .content form .forgot-password::before {
  content: '?';
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: -20px;
  color: #fff;
  text-align: center;
  background-color: #a20f10;
  border-radius: 50%;
}
.checkout-btn {
  font-size: 14px;
  height: 36px;
  width: 150px;
  margin: 20px 0;
  border: none;
  border-radius: 17px;
  box-shadow: inset 0 -2px 0 #e2b620;
  background: linear-gradient(#fbce34, #fcd85e, #fbce34);
  color: #0a537b;
  font-family: Verdana, sans-serif;
  text-transform: uppercase;
  outline: none;
}
.checkout-btn:hover {
  cursor: pointer;
}
.checkout-btn:active {
  box-shadow: inset 0 2px 0 #e2b620;
}
.checkout-steps .step.review div > .header,
.checkout-steps .step.address div > .header {
  width: auto;
  margin: 10px 0;
  margin-left: 40px;
  color: #000;
  font-weight: bold;
  font-family: Verdana, sans-serif;
  font-size: 11pt;
  display: flex;
  justify-content: flex-start;
}
.checkout-steps .step.address .choose-address .item {
  position: relative;
}
.checkout-steps .step.address .choose-address .item label {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 20px;
  margin: 5px 0;
  background-color: #fafbfb;
  border-radius: 20px;
  position: relative;
  font-family: Verdana, sans-serif;
  font-size: 11pt;
}
.checkout-steps .step.address .choose-address .item label::before {
  content: '';
  /*width: 14px;
  height: 14px;*/
  width: 16px;
  height: 16px;
  margin-right: 10px;
  border-radius: 50%;
  border: 1px solid #093954;
}
.checkout-steps .step.address .choose-address .item label:hover {
  cursor: pointer;
}
.checkout-steps .step.address .choose-address .item input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.checkout-steps .step.address .choose-address .item input[type="radio"]:checked + label:after {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #093954;
  position: absolute;
  top: 15px;
  left: 23px;
}
.checkout-steps .step.address .choose-address .item input[type="radio"]:checked + label {
  background-color: #d9edfa;
}
.checkout-steps .step.address .choose-address .add-address {
  height: 40px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  position: relative;
  font-family: Verdana, sans-serif;
  text-transform: uppercase;
  color: #093954;
}
.checkout-steps .step.address .choose-address .add-address::after {
  content: "+";
  width: 25px;
  height: 25px;
  margin-left: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 13pt;
  background-color: #093954;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkout-steps .step.address .choose-address .add-address:hover {cursor: pointer;}
.checkout-steps .step.address .collect-orders .checkbox {margin: 15px 0;}
.checkout-steps .step.address .collect-orders .checkbox input[type="checkbox"] {display: none;}
.checkout-steps .step.address .collect-orders .checkbox label {
  font-family: Verdana, sans-serif;
  font-size: 11pt;
  display: flex;
  align-items: center;
}
.checkout-steps .step.address .collect-orders .checkbox label:hover {cursor: pointer;}
.checkout-steps .step.address .collect-orders .checkbox label::before {
  content: '';
  width: 15px;
  height: 15px;
  padding: 5px;
  margin: 0 10px;
  border: 1px solid #093954;
  border-radius: 5px;
}
.checkout-steps .step.address .collect-orders .checkbox input[type="checkbox"]:checked + label::before {
  background: url(../img/icons/checked.svg) no-repeat 0 0;
}
.checkout-steps .step.address .collect-orders .info {
  margin: 5px 40px;
  margin-bottom: 40px;
  font-family: Verdana, sans-serif;
  font-size: 11pt;
}
.checkout-steps .step.address .comment-box .description span,
.checkout-steps .step.address .collect-orders .info span {
  display: block;
  margin: 10px 0;
  text-transform: uppercase;
}
.checkout-steps .step.address .comment-box .description {margin-bottom: 0;}
.checkout-steps .step.address .comment-box .comment {
  display: flex;
  flex-direction: column;
}
.checkout-steps .step.address .comment-box .comment textarea {
  height: 130px;
  padding: 5px;
  resize: none;
  font-family: Verdana, sans-serif;
  font-size: 10pt;
}
.checkout-steps .info-message {
  margin: 10px 0;
  margin-left: 40px;
  color: #093954;
  font-family: Verdana, sans-serif;
  font-style: italic;
  font-size: 11pt;
  position: relative;
}
.checkout-steps .info-message:before {
  content: "!";
  width: 20px;
  height: 20px;
  position: absolute;
  left: -35px;
  border-radius: 50%;
  margin: 0 7px;
  color: #fff;
  font-style: normal;
  text-align: center;
  background-color: #093954;
}
.checkout-steps .step.review .delivery-date .item,
.checkout-steps .step.payment .payment-items .item {
  min-height: 20px;
  margin: 10px 0;
  position: relative;
}
.checkout-steps .step.review .delivery-date .item input[type="radio"],
.checkout-steps .step.payment .payment-items .item input[type="radio"] {display: none;}
.checkout-steps .step.review .delivery-date .item label,
.checkout-steps .step.payment .payment-items .item label {
  text-transform: uppercase;
  font-family: Verdana, sans-serif;
  font-size: 11pt;
}
.checkout-steps .step.review .delivery-date .item label:hover,
.checkout-steps .step.payment .payment-items .item label:hover {cursor: pointer;}
.checkout-steps .step.review .delivery-date .item label::before,
.checkout-steps .step.payment .payment-items .item label::before {
  content: '';
  width: 16px;
  height: 16px;
  margin: 0 12px;
  display: inline-block;
  border: 1px solid #093954;
  border-radius: 50%;
}
.checkout-steps .step.review .delivery-date .item input[type="radio"]:checked + label::after,
.checkout-steps .step.payment .payment-items .item input[type="radio"]:checked + label::after {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  top: 3px;
  left: 15px;
  border-radius: 50%;
  background-color: #093954;
}
.checkout-steps .step.payment .payment-items .item .mess {
  max-width: 320px;
  margin-left: 40px;
  text-transform: uppercase;
  font-family: Verdand, sans-serif;
  font-size: 11pt;
  color: #4598c7;
}
.checkout-steps .step.payment .info-block .description {
  margin: 10px 40px;
}
.checkout-steps .step.payment .info-block .pays-list {
  height: 55px;
  margin: 0 10px;
}
.checkout-steps .step.payment .info-block .pays-list img {
  height: 100%;
  margin: 0 5px;
}
.checkout-steps .step.review .description > span {
  display: block;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.checkout-steps .step.review .description .info {margin: 10px 0;}
.checkout-steps .step.review .description .info span {
  color: #0a537b;
  font-weight: bold;
}
.checkout-steps .step.review .description .info .extra {display: block;}
.checkout-steps .step.review .description .info .extra::before {content: "(";}
.checkout-steps .step.review .description .info .extra::after {content: ")";}
.checkout-steps .step.review .cart-total {
  width: 300px;
  padding: 10px;
  font-family: Verdana, sans-serif;
  font-size: 11pt;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #f8fafb;
  border-radius: 60px;
}
.checkout-steps .step.review .cart-total .title {
  font-size: 11pt;
  font-weight: normal;
  letter-spacing: normal;
  text-transform: none;
  margin: 0;
  margin-top: 10px;
}
.checkout-steps .step.review .cart-total .price {
  font-size: 12pt;
  font-weight: bold;
  color: #0a537b;
}
.checkout-steps .step.review .cart-total .description {
  margin: 0;
  text-align: center;
}
.checkout-steps .step.review .cart-total .description a {
  color: #0b557e;
}

/******************** purchase-info *******************/
.carts .purchase-info {
  min-width: 230px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.carts .purchase-info .total {
  height: 90px;
  min-width: 240px;
  padding: 15px 0;
  border-radius: 60px;
  background-color: #f8fafb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.carts .purchase-info .total .text {
  text-align: center;
  font-family: Verdana, sans-serif;
  font-size: 11pt;
}
.carts .purchase-info .total .price {
  font-family: Verdana, sans-serif;
  font-size: 11pt;
  font-weight: bold;
  color: #0a537b;
}

/*********************** products *********************/
.carts .products {
  margin-right: 20px;
  flex: 3;
}
.carts .products .header {
  margin: 25px 0;
  display: flex;
}
.carts .products .header > div {
  font-family: Verdana, sans-serif;
  font-size: 10pt;
  color: #8e8e92;
  padding: 0 3px;
}
.carts .products .header .price,
.carts .products .header .quantity,
.carts .products .header .total {
  text-align: center;
}
.carts .products .products-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.carts .products .products-box .product {
  flex: 1;
  width: auto;
  height: 80px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #f1f7f9;
}
.carts .products .image {flex: 2;}
.carts .products .product-name {flex: 6;}
.carts .products .price {flex: 2;}
.carts .products .quantity {flex: 2;}
.carts .products .total {flex: 1;}
.carts .products .delete {flex: 1;}
.carts .products .products-box .product .delete {
  height: 100%;
  background-image: url(../img/icons/cross-out.png);
  background-repeat: no-repeat;
  background-position: center center;
}
.carts .products .products-box .product .image {
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}
.carts .products .products-box .product .image img {
  margin: 10px;
  object-fit: contain;
  width: 70%;
}
.carts .products .products-box .product .product-name {
  font-family: Verdana, sans-serif;
  font-size: 10pt;
  color: #23527c;
}
.carts .products .products-box .product .product-name a{
  color: #23527c;
}
.carts .products .products-box .product .quantity {
  display: flex;
  align-items: center;
  justify-content: center;
}
.carts .products .products-box .product .price,
.carts .products .products-box .product .total {
  text-align: center;
  font-family: Verdana, sans-serif;
  font-size: 10pt;
}
.carts .products .products-box .product .price span {display: block;}
.carts .products .products-box .product .price span,
.carts .products .products-box .product .total {
  font-weight: bold;
  color: #0a537b;
}
.carts .products .products-box .product .delete:hover {cursor: pointer;}

/******************************************************/
/*                       Brands                       */
/******************************************************/
.brands {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f8f9fb;
}
.brands .container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.brands .container::after {
  content: '';
  width: 100%;
  order: 0;
}
.brands .container a {order: 0;}
.brands .item {
  max-height: 50px;
  max-width: 100px;
  width: auto;
  margin: 35px 0;
}


.c_shadow{
  -webkit-box-shadow: 0px 0px 30px 0px #f8fbfd;
  -moz-box-shadow: 0px 0px 30px 0px #f8fbfd;
  box-shadow: 0px 0px 30px 0px #f8fbfd;
}
//// owl-carousel custom

.owl-carousel{
  .owl-stage{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  &.items{
    //padding: 0 25px;
    padding: 0;
    .owl-stage-outer{
      padding: 30px 0;
    }
  }
  .owl-stage-outer{
    .owl-item{
      .item{
        height: 100%;
        .buy{
          display: none;
        }
      }
      img{
        max-width: 100%;
        width: auto;
      }
    }
  }
  .owl-nav{
    text-align: center;
    line-height: 0;
    @include respond-to(ipad){
      position: absolute;
      top: -62px;
      left: auto;
      right: 0;
    }
    button{
      //position: absolute;
      top: 50%;
      width: 40px;
      height: 40px;
      &:hover{
        svg{
          path{
            fill: #1d78ab;
          }
        }
      }
    }
    .owl-prev{
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
      left: 0;
      @include respond-to(ipad){
        /*-webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);*/
        //left: -50px;
      }
      @include respond-to(desktop){
        left: 0;
      }
      @include respond-to(large_desktop){
        //left: -50px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
    .owl-next{
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg);
      right: 0;
      @include respond-to(ipad){
        /*-webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);*/
        //right: -50px;
      }
      @include respond-to(desktop){
        right: 0;
      }
      @include respond-to(large_desktop){
        //right: -50px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
  }
}
// content carts

.content{
  &.carts{
    .main{
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
  }
  &.checkout{
    .main{
      /*width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;*/
      .checkout-aside{
        .order-info{
          margin: 0;
          width: 100%;
          padding: 0;
          //width: 340px;
          height: auto;
          //padding: 20px;
          //margin-left: 20px;
          //flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          box-shadow: 0 0 50px #006da80f;
          .header{
            width: 100%;
            justify-content: flex-start;
            .text{
              margin: 0 10px 0 0;
            }
          }
        }
        .info_cart_copy{
          &_inner{
            display: flex;
            flex-direction: column;
            align-items: center;
            > div{
              width: 100%;
              padding: 10px 15px;
              float: none;
              font-size: 12px;
              &.col-md-4{
                font-size: 15px;
              }
            }
            .baskets_free{
              margin: 0;
              font-size: 16px;
              @include respond-to(ipad){
                font-size: 18px;
              }
              @include respond-to(desktop){
                font-size: 20px;
              }
            }
          }
        }
      }
      .checkout-steps{
        //flex: 2;
        //display: flex;
        //flex-direction: column;
      }
    }
  }
}

// banner_campaign

.banner_campaign{
  background-position: 50% 50%;
  background-repeat: no-repeat !important;
  background-size: cover;

  min-height: 200px;
  display: flex;
  align-items: center;

  //margin-bottom: 30px;
  padding: 15px;
  @include respond-to(ipad){
    padding: 30px 30px 40px 230px;
  }
  @include respond-to(desktop){
    padding: 30px 30px 40px 230px;
  }
  @include respond-to(large_desktop){
    padding: 30px 30px 40px 230px;
  }
  p{
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}

// filter_products
h3.sub_title{
  position: relative;
  color: #1d1f23;
  font-size: 20px;
  text-transform: uppercase;
  display: block;
  margin: 0 0 15px;
  span{
    position: relative;
    color: #1d1f23;
    font-size: 20px;
    background-color: #fff;
    display: inline-block;
    padding: 5px 10px 5px 0;
    letter-spacing: 2px;
    z-index: 2;
  }
  &:after{
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    right: 0;
    height: 4px;
    background-color: #f4f6f8;
    border-radius: 6px;
  }
}
.filter_products{
  padding-top: 15px;
  .fresh_list{
    text-align: center;
    font-size: 0;
    @extend .row;
    li{
      width: 50%;
      padding: 15px;
      display: inline-block;
      @include respond-to(ipad){
        float: left;
        padding: 15px;
        min-height: 1px;
        width: 33.333%;
      }
      @include respond-to(desktop){
        width: 20%;
      }
      @include respond-to(large_desktop){
        width: 20%;
      }
      div{
        position: relative;
        padding-bottom: 100%;
        text-align: center;
        img{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          max-width: 100%;
          max-height: 100%;
        }
        a{
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: auto;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 12px 10px;
          text-decoration: underline;
          color: #fbd13f;
          font-size: 14px;
          background: rgba(13, 76, 110, .5);
          -webkit-transition: all 200ms ease-in-out;
          -moz-transition: all 200ms ease-in-out;
          -ms-transition: all 200ms ease-in-out;
          -o-transition: all 200ms ease-in-out;
          transition: all 200ms ease-in-out;
        }
      }
      &:hover{
        div{
          a{
            top: 0;
          }
        }
      }
    }
  }
  .filter_brand{
    .title{
      font-size: 12px;
      text-transform: uppercase;
      color: #212e40;
      font-weight: 400;
      letter-spacing: 1px;
    }
  }
  .row{
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }
  .col-md-3{
    min-height: 1px;
    padding: 15px;
    float: left;
    width: 50%;
    @include respond-to(ipad){
      float: left;
      width: 50%;
    }
    @include respond-to(desktop){
      width: 33.333%;
    }
    @include respond-to(large_desktop){
      width: 25%;
    }
  }
  .products-box{
    text-align: center;
    .col-md-3{
      border-bottom: 1px solid #f4f6f8;
      margin-top: -1px;
      width: 50%;
      @include respond-to(ipad){
        width: 33.333%;
      }
      @include respond-to(desktop){
        width: 20%;
      }
    }
    .item{
      border-left: 0;
      .count{
        //right: -12px !important;
      }
    }
  }
  .view_more{
    margin-top: 30px;
    font-size: 11px;
    display: inline-block;
    width: 100%;
    max-width: 150px;
    text-transform: uppercase;
    text-align: center;
    border-radius: 20px;
    padding: 10px 15px;
    border: 1px solid transparent;
    color: #e7f1f7;
    background-color: #006ca8;
    box-shadow: 0px 2px 0px 0px #006ca8;
    transition: all 200ms ease-in-out;
    background: linear-gradient(#006ca8, #1f79ac, #006ca8);
  }
}

.filter_products{
    .products-box{
      &.container{
        .row{
          border-bottom: 1px solid #f4f6f8;
          border-right: 1px solid #f4f6f8;
        }
      }
      .col-md-3{
        border-top: 1px solid #f4f6f8;
        border-left: 1px solid #f4f6f8;
        padding: 10px 2px;
        &:last-of-type{
          //border-right: 1px solid #f4f6f8;
        }
      }
      .item{
        border-left: 0;
      }
    }
  }

/*.linear_g{
  padding: 30px 0 0 0;
  background: linear-gradient(#f8fbfd, #fff, #fff);
  .select2{
    width: auto!important;
    background-color: #fbfcfd;
    .select2-selection{
      border: 1px solid #f1f6f8;
      border-radius: 0;
      color: #212e40;
      height: auto;
      .select2-selection__rendered{
        color: #212e40;
        font-size: 14px;
        height: auto;
        padding: 5px 75px 5px 15px;
        @include respond-to(ipad){
          padding: 5px 100px 5px 15px;
        }
        @include respond-to(desktop){
          padding: 5px 145px 5px 15px;
        }
      }
      .select2-selection__arrow{
        height: auto;
        bottom: 0;
      }
    }
  }
}*/




.main{
  .products{
    &.slider{
      width: 100%;
      float: none;
    }
  }
}

.registration_block{
  @include respond-to(ipad){
    padding: 25px 0 170px;
  }
  @include respond-to(desktop){
    padding: 30px 0 200px;
  }
  @include respond-to(large_desktop){
    padding: 40px 0 260px;
  }
  .buy{
    height: auto;
    width: auto;
    border: none;
    border-radius: 17px;
    box-shadow: inset 0 -2px 0 #e2b620;
    background: linear-gradient(#fbce34, #fcd85e, #fbce34);
    color: #0a537b;
    font-family: Verdana, sans-serif;
    text-transform: uppercase;
    outline: none;
    padding: 12px 20px;
    font-size: 11px;
    font-weight: 400;
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    &:hover{
      box-shadow: inset 0 -3px 0 #e2b620;
      background: linear-gradient(#fcd85e, #fbce34, #fcd85e);
    }
  }
  .general_registration{
    display: block;
    h2.title{
      color: #1d1f23;
      font-size: 25px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-bottom: 30px;
    }
    h4{
      font-size: 16px;
      color: #313138;
      font-weight: 700;
      letter-spacing: -.5px;
      margin-bottom: 20px;
    }
    label{
      display: block;
      margin-bottom: 15px;
      span{
        display: block;
        padding: 5px 15px 5px 25px;
        color: #000;
        font-size: 15px;
        font-weight: 400;
      }
      input{
        border: 1px solid #7f7f7f;
        width: 100%;
        display: block;
        color: #000;
        font-size: 14px;
        font-weight: 400;
        padding: 8px 15px;
        @include respond-to(desktop){
          max-width: 300px;
        }
      }
    }
  }
  .wholesale_registration{
    display: block;
    padding-left: 0;
    @include respond-to(desktop){
      padding-left: 80px;
    }
    .title{
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 15px;
    }
    p{
      font-size: 15px;
      margin-bottom: 15px;
    }
    label{
      display: block;
      margin-bottom: 15px;
      span{
        display: block;
        padding: 5px 15px 5px 25px;
        color: #000;
        font-size: 15px;
        font-weight: 400;
      }
      input{
        border: 1px solid #7f7f7f;
        width: 100%;
        display: block;
        color: #000;
        font-size: 14px;
        font-weight: 400;
        padding: 8px 15px;
        @include respond-to(desktop){
          max-width: 300px;
        }
      }
    }
  }
}

// similar_products
.similar_products{}

// product_details
.product_details{
  .col-md-4, .col-md-8{
    padding: 15px;
    @include respond-to(ipad){
      padding: 0 15px;
    }
  }
  .image{
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    @include respond-to(ipad){
      padding: 50px;
    }
  }
  .desc{
    background: #fff;
    padding: 15px;
    @include respond-to(ipad){
      padding: 50px 0;
    }
    .title{
      color: #1d1f23;
      font-size: 20px;
      margin-bottom: 15px;
    }
    .info{
      position: relative;
      @include respond-to(ipad){
        //display: flex;
        //align-items: center;
        //justify-content: space-between;
      }
      .left{
        padding: 15px 0;
        width: 100%;
        @include respond-to(ipad){
          width: auto;
        }
        .price{
          color: #1d1f23;
          font-family: Verdana, sans-serif;
          margin-bottom: 15px;
          font-size: 20px;
          span{
            font-weight: bold;
            color: #0a537b;
          }
        }
        .buy{
          height: auto;
          width: auto;
          padding: 15px 50px;
          border-radius: 40px;
          border: none;
          box-shadow: inset 0 -2px 0 #e2b620;
          background: linear-gradient(#fbce34, #fcd85e, #fbce34);
          color: #0a537b;
          font-family: Verdana, sans-serif;
          text-transform: uppercase;
          outline: none;
          transition: all 200ms ease-in-out;
          &:hover{
            background: linear-gradient(#fcd85e, #fbce34, #fcd85e);
          }
        }
      }
      .socials_list{
        margin: -52px 0 0 0;
        text-align: right;
        font-size: 0;
        a{
          width: 100%;
          display: inline-block;
          max-width: 30px;
          margin: 2px;
        }
      }
      .right{
        position: relative;
        border-top: 6px solid #1d78ab;
        border-bottom: 3px solid #1d78ab;
        width: 100%;
        max-width: 100%;
        padding: 25px 15px 30px;
        background: #fbfcfd;
        font-size: 15px;
        color: #212e40;
        @include respond-to(ipad){
          width: auto;
          max-width: 240px;
        }
        .title{
          margin: 0 0 5px;
          font-size: 16px;
          text-transform: uppercase;
        }
        .tel{
          display: block;
          margin: 0 0 5px;
          font-size: 18px;
          color: #212e40;
          &:hover{
            text-decoration: underline;
          }
        }
        .email{
          display: block;
          margin: 0 0 5px;
          font-size: 15px;
          color: #212e40;
          &:hover{
            text-decoration: underline;
          }
        }
        p{
          font-size: 15px;
        }
        img{
          position: absolute;
          top: 15px;
          right: 15px;
        }
      }
    }
  }
}

// info_cart_copy
.info_cart_copy{
  margin: 15px -15px;
  @include respond-to(ipad){
    margin: 15px 0;
  }
  &_inner{
    position: relative;
    color: #fff;
    padding: 10px 0;
    background-color: #3c9479;
    border-bottom: 3px solid #34715e;
    @extend .row;
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 13px;
    flex-wrap: wrap;
    text-align: center;
    @include respond-to(ipad){
      text-align: left;
      flex-wrap: nowrap;
      font-size: 14px;
    }
    @include respond-to(desktop){
      font-size: 16px;
    }
    > div{
      padding-top: 10px;
      padding-bottom: 10px;
      @include respond-to(ipad){
        padding-top: 0;
        padding-bottom: 0;
      }
      &.col-md-4{
        @include respond-to(ipad){
          width: 30%;
        }
      }
      &.col-md-5{
        @include respond-to(ipad){
          width: 50%;
        }
      }
      &.col-md-3{
        @include respond-to(ipad){
          width: 20%;
        }
      }
    }
    .baskets_free{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      color: #cee0da;
      @include respond-to(ipad){
        justify-content: flex-start;
        margin: 0 -15px;
      }
      li{
        padding: 3px;
        &.p_checked{
          a{
            background-color: #32d0a0;
            border: 1px solid #32d0a0;
          }
        }
        &.p_free{
          a{
            background-color: #fbd243;
            border: 1px solid #fbd243;
          }
        }
        a{
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px dashed #fff;
          border-radius: 50%;
          @include respond-to(ipad){
            width: 40px;
            height: 40px;
          }
          @include respond-to(desktop){
            width: 45px;
            height: 45px;
          }
        }
        img{
          max-width: 18px;
          @include respond-to(ipad){
            max-width: 22px;
          }
          @include respond-to(desktop){
            max-width: 100%;
          }
        }
      }
    }
    .h_Text{
      font-size: 12px;
      padding-right: 10px;
      color: #cee0da;
    }
    .close_info_cart_copy{
      display: block;
      width: 15px;
      height: 15px;
      position: absolute;
      right: 10px;
      top: 50%;
      z-index: 2;
      -webkit-transform: translate(0, -50%);
      -moz-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      -o-transform: translate(0, -50%);
      transform: translate(0, -50%);
      &:before, &:after{
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: #cee0da;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      &:after{
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}


.select2-container--default{
  .select2-selection--single{
    border-radius: 0;
    background-color: #fbfcfd;
    border: 1px solid #f1f6f8;
    height: auto;
    position: relative;
    min-width: 220px;
    .select2-selection__rendered{
      line-height: normal;
      padding: 10px 20px;
      max-width: 100%;
    }
    .select2-selection__arrow{
      right: 0;
      top: 50%;
      -webkit-transform: translate(0, -50%);
      -moz-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      -o-transform: translate(0, -50%);
      transform: translate(0, -50%);;
    }
  }
}
// more_block
.more_block{
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  .inner{
    position: relative;
    padding: 30px 120px 30px 20px;
    background-color: #3c9479;
    border-bottom: 3px solid #34715e;
  }
  .read_more{
    text-transform: uppercase;
    border: 2px solid #fff;
    font-size: 11px;
    border-radius: 30px;
    padding: 7px 25px;
    font-weight: 700;
    color: #fff;
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    &:hover{
      background-color: #fff;
      color: #34715e;
    }
  }
}
// common .product_details .count
body{
  .product_details{
    .count{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 15px 0;
      button{
        border: 0;
        margin: 0;
        &.remove{
          position: relative;
          background-color: #0a537b;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            display: none;
          }
          &:before{
            content: '';
            width: 20px;
            height: 1px;
            background-color: #fff;
          }
        }
        &.add{
          position: relative;
          background-color: #0a537b;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            display: none;
          }
          &:before{
            content: '';
            width: 20px;
            height: 1px;
            background-color: #fff;
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          }
          &:after{
            content: '';
            width: 20px;
            top: 50%;
            left: 50%;
            height: 1px;
            background-color: #fff;
            transform: rotate(90deg) translate(0, -50%);
          }
        }
      }
      input{
        margin: 0 10px;
        border: 1px solid #e0e9ed;
        background-color: #f4f8fa;
        color: #87898b;
        font-family: Verdana, sans-serif;
        text-align: center;
        outline: none;
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
  }
}
.u_title{
  p{
    font-size: 12px;
    strong{}
  }
}
// .g_contains
.g_contains{
  padding: 20px 0 40px;
  .u_title{
    border-bottom: 3px solid #f4f6f8;
    padding-bottom: 5px;
  }
  .gifts_list{
    @extend .row;
    display: flex;
    flex-wrap: wrap;
    li{
      min-height: 1px;
      padding: 15px;
      width: 50%;
      float: left;
      @include respond-to(ipad){
        width: 33.333%;
      }
      .inner{
        display: flex;
        align-items: normal;
        .img{
          width: 100%;
          max-width: 80px;
        }
        .desc{
          font-size: 10px;
          @include respond-to(ipad){
            font-size: 12px;
          }
          a{
            color: #0a537b;
            font-size: 10px;
            @include respond-to(ipad){
              font-size: 12px;
            }
            &:hover{
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
// accordion
#accordion{
  .panel{
    &-heading{
      .panel-title{
        a{
          display: block;
        }
      }
    }
  }
}