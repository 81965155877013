.contact_wrap{
  padding: 30px 0;
  &.contact_wrap2{
    position: relative;
    min-height: 0;
    @include respond-to(ipad){
      min-height: 500px;
    }
    .info_contacts{
      display: none;
      @include respond-to(ipad){
        display: block;
      }
    }
  }
  &_m{
    @include respond-to(ipad){
      display: none;
      visibility: hidden;
      position: fixed;
      left: -9999px;
      height: 0;
      width: 0;
    }
  }
}
.contact_list{
  display: block;
  li{
    padding: 15px 0;
    @extend .row;
    margin: 0;
    > span{
      float: left;
      width: 30%;
      min-height: 1px;
      padding: 0 15px 0 0;
      font-size: 14px;
      @include respond-to(ipad){
        width: 35%;
      }
      &:last-of-type{
        word-break: break-all;
        padding: 0 0 0 15px;
        font-weight: 700;
        color: #000;
        width: 70%;
        @include respond-to(ipad){
          width: 65%;
        }
      }
    }
    a{
      display: block;
      color: #0a537b;
      font-weight: 700;
      font-size: 12px;
      @include respond-to(ipad){
        font-size: 14px;
      }
      &.v_a_m{
        display: inline-block;
        padding-right: 5px;
      }
    }
    input[type=text]{
      background-color: #fff;
      background-image: none !important;
      border: 1px solid rgba(0,0,0,.07);
      -webkit-appearance: none;
      color: #2c2c2c;
      outline: 0;
      height: 35px;
      padding: 9px 12px;
      line-height: normal;
      font-size: 14px;
      font-weight: 400;
      vertical-align: middle;
      min-height: 35px;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-radius: 2px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      width: 100%;
    }
    label{
      width: 100%;
      display: block;
      &.label_wrap{
        position: relative;
        &:after{
          content: '';
          position: absolute;
          right: 10px;
          top: calc(50% + 5px);
          border: 5px solid transparent;
          border-top: 5px solid #000;
          -webkit-transform: translate(0, -50%);
          -moz-transform: translate(0, -50%);
          -ms-transform: translate(0, -50%);
          -o-transform: translate(0, -50%);
          transform: translate(0, -50%);
          z-index: 1;
        }
      }
    }
    textarea{
      display: block;
      font-size: 14px;
      width: 100%;
      max-width: 100%;
      background-color: #fff;
      background-image: none !important;
      border: 1px solid rgba(0,0,0,.07);
      -webkit-appearance: none;
      color: #2c2c2c;
      outline: 0;
      padding: 9px 12px;
      line-height: normal;
      font-weight: 400;
      vertical-align: middle;
      min-height: 35px;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-radius: 2px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
    }
    select{
      width: 100%;
      background-color: #fff;
      background-image: none !important;
      border: 1px solid rgba(0,0,0,.07);
      -webkit-appearance: none;
      color: #2c2c2c;
      outline: 0;
      height: 35px;
      padding: 9px 12px;
      line-height: normal;
      font-size: 14px;
      font-weight: 400;
      vertical-align: middle;
      min-height: 35px;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-radius: 2px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      text-transform: uppercase;
      margin: 15px 0;
    }
  }
}
.v_a_m{
  vertical-align: middle;
}
h1{
  &.contact_title {
    text-align: center;
    text-transform: uppercase;
    font-size: 28px;
    padding: 15px 0;
    @include respond-to(ipad){
      text-align: left;
      font-size: 36px;
    }
  }
}
h2{
  &.contact_title{
    text-align: center;
    font-size: 18px;
    text-decoration: underline;
    padding: 15px 0;
    @include respond-to(ipad){
      text-align: left;
      font-size: 24px;
    }
  }
}
h3{
  &.contact_title{
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 300;
    padding: 15px 0;
    @include respond-to(ipad){
      font-size: 18px;
    }
  }
}
.contact_inner_wrap{
  padding-top: 15px;
  padding-bottom: 15px;
  -webkit-box-shadow: inset 0 0 0 #000;
  -moz-box-shadow: inset 0 0 0 #000;
  box-shadow: inset 0 0 0 #000;
}
// contact2
#map2{
  width: 100%;
  height: 300px;
  @include respond-to(ipad){
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.info_contacts{
  position: static;
  .inner{
    background: rgba(255, 255, 255, .75);
  }
}