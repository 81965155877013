.breadcrumbs{
  ul{
    display: flex;
    align-items: center;
    @extend .row;
    li{
      padding: 15px;
      font-size: 15px;
      color: #9e9e9e;
      a{
        font-size: 15px;
        color: #1d1f23;
        border-right: 1px solid #1d1f23;
        padding-right: 30px;
      }
    }
  }
}