.account_block{
  padding: 50px 0 120px;
  h2.title{
    color: #1d1f23;
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 30px;
  }
  h4{
    font-size: 16px;
    color: #313138;
    font-weight: 700;
    letter-spacing: -.5px;
    margin-bottom: 20px;
  }
  .info_profile{
    width: 100%;
    tr{
      background-color: #fbfbfb;
      &:nth-child(even){
        background-color: transparent;
      }
      td{
        color: #8f8f8f;
        padding: 8px 12px;
        font-size: 15px;
        &:last-child{
          color: #000;
        }
        a{
          text-decoration: none;
          color: #000;
          font-size: 15px;
          -webkit-transition: all 200ms ease-in-out;
          -moz-transition: all 200ms ease-in-out;
          -ms-transition: all 200ms ease-in-out;
          -o-transition: all 200ms ease-in-out;
          transition: all 200ms ease-in-out;
          &:hover{
            color: #8f8f8f;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .account_options{
    li{
      padding: 8px 12px;
      border-radius: 20px;
      background-color: #fafcfc;
      margin-bottom: 10px;
      color: #0a537b;
      -webkit-transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      -ms-transition: all 200ms ease-in-out;
      -o-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out;
      &:last-child{
        margin-bottom: 0;
      }
      &.active{
        background-color: #0a537b;
        color: #fff;
        a{
          color: #fff;
        }
      }
      a{
        color: #0a537b;
        display: block;
        font-size: 12px;
        text-transform: uppercase;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        img{
          margin: 0 10px 0 0;
          display: block;
          &:last-of-type{
            display: none;
          }
        }
      }
      &:hover, &.active{
        background-color: #0a537b;
        color: #fff;
        a{
          color: #fff;
          img{
            display: none;
            &:last-of-type{
              display: block;
            }
          }
        }
      }
    }
  }
  .products-box{
    max-width: 740px;
    .col-md-3{
      position: relative;
      border-top: 1px solid #f4f6f8;
      border-bottom: 1px solid #f4f6f8;
      border-left: 1px solid #f4f6f8;
      margin-top: -1px;
      .item{
        border: 0;
        position: static;
      }
      &:nth-child(4){
        border-right: 1px solid #f4f6f8;
      }
      &:last-of-type{
        border-right: 1px solid #f4f6f8;
      }
    }
    .label{
      position: absolute;
      top: 10px;
      right: 10px;
      width: auto;
      height: auto;
      z-index: 1;
      left: auto;
      img{
        width: auto;
        max-width: 100%;
        margin-bottom: 0;
      }
    }
  }
}
.order_table{
  max-width: 800px;
  width: 100%;
  tr{
    background-color: #fbfbfb;
    &:first-of-type{
      background-color: transparent;
    }
    th{
      font-weight: 400;
      color: #858585;
      padding: 10px;
      &:first-of-type{
        text-align: center;
      }
    }
    td{
      border-bottom: 3px solid #fff;
      vertical-align: middle;
      color: #000;
      padding: 10px;
      &:first-of-type{
        color: #0a537b;
        text-align: center;
        background-color: #e3f0f7;
      }
    }
  }
}
.change_password{
  p{
    color: #313138;
    font-size: 16px;
    margin-bottom: 15px;
  }
  label{
    margin-bottom: 15px;
    display: block;
    span{
      display: block;
      padding: 5px 15px 5px 25px;
      color: #000;
      font-size: 15px;
      font-weight: 400;
    }
    input{
      border: 1px solid #7f7f7f;
      width: 100%;
      display: block;
      color: #000;
      font-size: 14px;
      font-weight: 400;
      padding: 8px 15px;
      max-width: 300px;
    }
  }
  .change_pass_btn{
    height: auto;
    width: auto;
    border: none;
    border-radius: 17px;
    box-shadow: inset 0 -2px 0 #e2b620;
    background: linear-gradient(#fbce34, #fcd85e, #fbce34);
    color: #0a537b;
    font-family: Verdana, sans-serif;
    text-transform: uppercase;
    outline: none;
    padding: 12px 20px;
    font-size: 11px;
    font-weight: 400;
    transition: all 200ms ease-in-out;
    &:hover{
      box-shadow: inset 0 -3px 0 #e2b620;
      background: linear-gradient(#fcd85e, #fbce34, #fcd85e);
    }
  }
}