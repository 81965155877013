.slide_menu{
  padding-right: 0;
  height: 100%;
  width: 100%;
  left: -100%;
  background-color: #006ca8;
  background-image: linear-gradient(to bottom, #006ca8, #1e79ac, #006ca8);
  border-bottom: 3px solid #0a537b;
  min-height: 100%;
  position: fixed;
  z-index: 99999;
  top: 0;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff;
  @include respond-to(ipad){
    display: none;
  }
  &.slided{
    left: 0;
  }
  .slideMenuClose{
    text-align: right;
    padding: 15px;
    /*&:before, &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 2px;
      background-color: #fff;
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
    }
    &:after{
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }*/
  }
  .nano .nano-content,
  .nano .nano-pane{
    //position: relative;
  }
  .nano{
    position: relative;
    width: 100%;
    height: 100%;
    .slideMenuContainer {
      height: 100%;
      display: block;
      min-height: 100%;
      width: 100%;
      padding-top: 35px;
      .slideMenuContainerInner{
        position: relative;
        height: 100%;
        padding: 15px;
      }
    }
  }
  nav{
    margin: 0 0 20px 0;
  }
  .menu{
    display: block;
    background: none;
    border: 0;
    font-size: 14px;
    .offers{
      display: block;
      margin: 0 auto;
      width: auto;
      height: auto;
      text-align: center;
      .icon{
        display: inline-block;
      }
      .text{
        font-size: 14px;
      }
    }
    .menu-items{
      display: block;
      margin: 0 auto;
      text-align: center;
      a{
        display: block;
        height: auto;
        text-align: center;
        padding: 10px 15px;
        font-size: 14px;
        text-transform: uppercase;
        color: #fff;
      }
    }
    .delivery-opts{
      margin: 0 auto;
      font-size: 14px;
      width: auto;
      max-width: 75%;
    }
  }
  .btn{
    margin: 0 auto;
    display: block;
    border: 2px solid #fff;
    width: 90%;
  }
  &_socials{
    padding: 15px;
    ul{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      justify-content: center;
      li{
        padding: 0 15px;
      }
    }
  }
  .menu-item-has-children{
    position: relative;
    .arrow_down{
      width: 12px;
      height: 12px;
      position: absolute;
      top: 14px;
      right: 25px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
}
// nano
.nano{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.nano .nano-pane {
  background: rgba(0,0,0,0.25);
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 50;
  visibility: hidden\9;
  opacity: .01;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #5b5b5b;
  width: 4px;
  right: 15px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.nano > .nano-pane > .nano-slider {
  background: #9d9d9d;
  position: relative;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.nano > .nano-content {
  position: absolute;
  overflow-x: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.mobile_btn{
  position: absolute;
  z-index: 999;
  top: 10px;
  left: 0;
  width: 30px;
  height: 4px;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform;
  background-color: #b8a146;
  @include respond-to(ipad){
    display: none;
  }
  &.active{
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
    -moz-transform: translate3d(0, 10px, 0) rotate(45deg);
    -ms-transform: translate3d(0, 10px, 0) rotate(45deg);
    -o-transform: translate3d(0, 10px, 0) rotate(45deg);
    transform: translate3d(0, 10px, 0) rotate(45deg);
    &:before{
      -webkit-transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
      -moz-transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
      -ms-transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
      -o-transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
      transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
      opacity: 0;
    }
    &:after{
      -webkit-transform: translate3d(0, -16px, 0) rotate(-90deg);
      -moz-transform: translate3d(0, -16px, 0) rotate(-90deg);
      -ms-transform: translate3d(0, -16px, 0) rotate(-90deg);
      -o-transform: translate3d(0, -16px, 0) rotate(-90deg);
      transform: translate3d(0, -16px, 0) rotate(-90deg);
    }
  }
  &:before{
    content: '';
    position: absolute;
    width: 30px;
    height: 4px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    background-color: #b8a146;
    top: 8px;
  }
  &:after{
    content: '';
    position: absolute;
    width: 30px;
    height: 4px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    background-color: #b8a146;
    top: 16px;
  }
}