.main_header{
  .menu{
    display: none;
    @include respond-to(ipad){
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  }
  .search_elem{
    line-height: 0;
    display: none;
    @include respond-to(ipad){
      display: none;
    }
    svg{
      width: 19px;
      height: 19px;
      path{
        fill: #fff;
      }
    }
  }
}
.head-panel {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: Verdana, sans-serif;
  font-size: 10pt;
  //background-color: #093954;
  background-color: #3b3d43;
  .h-p-inner{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #d4e3ec;
    position: relative;
    padding-left: 50px;
    @include respond-to(ipad){
      padding-left: 0;
    }
    .panel-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 40px;
      .icon {
        width: 25px;
        height: 25px;
        margin: 0 8px;
        @include respond-to(ipad){
          width: 13px;
          height: 13px;
        }
        img {
          height: 100%;
        }
      }
      &:hover{
        color: #fff;
        opacity: .8;
      }
      &.email{
        @include respond-to(ipad){
          display: flex;
        }
      }
    }
    .languages {
      &:hover {
        cursor: pointer;
      }
      .list {
        width: 17px;
        height: 17px;
        border-radius: 50%;
      }
      .arrow {
        width: 9px;
        height: 17px;
        margin-left: 10px;
        img {
          width: 100%;
        }
      }
    }
    .show-message {
      display: none;
      @include respond-to(ipad){
        display: block;
        padding: 3px 10px;
        position: absolute;
        top: 100%;
        right: 0;
        background-color: rgba(254, 245, 215, 0.6);
        color: #093954;
        font-family: 'HelveticaNeueCyr Medium', sans-serif;
      }
    }
    .contacts {
      display: none;
      @include respond-to(ipad){
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .socials {
      display: none;
      @include respond-to(ipad){
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
      .item {
        width: 30px;
        height: 30px;
        margin: 0 5px;
        border-radius: 50%;
        &.item:hover {
          cursor: pointer;
          box-shadow: 0 0 2px #77a4bd;
        }
      }
    }
    .registration, .login {
      &:hover{
        cursor: pointer;
      }
    }
    .user {
      @include respond-to(ipad){
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
      }
      .registration{
        display: none;
        @include respond-to(ipad){
          display: flex;
        }
      }
      .login{
        float: left;
        @include respond-to(ipad){
          float: none;
        }
      }
      .busket_m{
        padding: 5px 0 0 10px;
        width: 40px;
        height: 100%;
        float: right;
        @include respond-to(ipad){
          display: none;
        }
        svg{
          fill: #fff;
        }
      }
    }
    .search-panel-m{
      display: block;
      @include respond-to(ipad){
        display: none;
      }
    }
  }
}
.panel-item{
  &.login{
    color: #d4e3ec;
    &:focus{
      color: #d4e3ec;
    }
    + .login_form_wrap{
      display: none;
      background-color: #ebf1f6;
      -webkit-box-shadow: 0px 0px 12px 0px rgba(221,235,243,1);
      -moz-box-shadow: 0px 0px 12px 0px rgba(221,235,243,1);
      box-shadow: 0px 0px 12px 0px rgba(221,235,243,1);
      position: absolute;
      top: 100%;
      right: 0;
      left: auto;
      width: 100%;
      max-width: 300px;
      z-index: 12;
      padding: 15px;
      @include respond-to(ipad){
        padding: 30px 30px 40px 30px;
      }
      h3{
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 10px;
        padding-left: 5px;
      }
      form{
        label{
          font-size: 14px;
          font-weight: 500;
          display: block;
          color: #000;
          span{
            padding-left: 10px;
            color: #000;
            margin: 0 0 5px 0;
            display: block;
          }
          input{
            width: 100%;
            display: block;
            padding: 8px;
            text-align: left;
            border: 1px solid #9bc8e1;
            background-color: transparent;
          }
          input[type=submit]{
            width: 100%;
            max-width: 150px;
            font-size: 11px;
            text-transform: uppercase;
            text-align: center;
            //border-radius: 20px;
            padding: 10px 15px;
            //border: 1px solid transparent;
            //color: #e7f1f7;
            background-color: #fbd44d;
            /*-webkit-box-shadow: 0px 2px 0px 0px rgba(226,182,32,1);
            -moz-box-shadow: 0px 2px 0px 0px rgba(226,182,32,1);
            box-shadow: 0px 2px 0px 0px rgba(226,182,32,1);*/
            -webkit-transition: all 200ms ease-in-out;
            -moz-transition: all 200ms ease-in-out;
            -ms-transition: all 200ms ease-in-out;
            -o-transition: all 200ms ease-in-out;
            transition: all 200ms ease-in-out;


            border: none;
            border-radius: 17px;
            box-shadow: inset 0 -2px 0 #e2b620;
            background: linear-gradient(#fbce34, #fcd85e, #fbce34);
            font-family: Verdana, sans-serif;
            //text-transform: uppercase;
            outline: none;
            color: #fff;
            &:hover{
              box-shadow: inset 0 -3px 0 #e2b620;
              background: linear-gradient(#fcd85e, #fbce34, #fcd85e);
              //border: 1px solid #fbd44d;
              //color: #fbd44d;
              //background-color: transparent;
            }
          }
        }
      }
      .links{
        display: block;
        a{
          display: block;
          font-size: 13px;
          font-weight: 400;
          color: #000;
          &.forgot_password{
            position: relative;
            padding: 10px 0 10px 20px;
            color: #a20f10;
            &:before{
              content: '?';
              border-radius: 50%;
              position: absolute;
              left: 0;
              top: 50%;
              color: #fff;
              width: 15px;
              height: 15px;
              background: #a20f10;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              -webkit-transform: translate(0, -50%);
              -moz-transform: translate(0, -50%);
              -ms-transform: translate(0, -50%);
              -o-transform: translate(0, -50%);
              transform: translate(0, -50%);
              font-size: 11px;
            }
          }
          &.register_new_account{
            color: #1273aa;
            padding: 0 0 10px 0;
          }
        }
      }
    }
    &.opened{
      + .login_form_wrap{
        display: block;
      }
    }
  }
}
.header {
  &_inner{
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 10px 0;
    @include respond-to(ipad){
      padding-top: 25px;
      padding-bottom: 15px;
      -ms-flex-pack: distribute;
      justify-content: space-around;
    }
    @include respond-to(desktop){
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    .logos {
      display: flex;
      align-items: center;
    }
    .logo {
      width: 100%;
      max-width: 180px;
      display: block;
      padding: 0 15px 0 0;
      @include respond-to(desktop){
        padding: 0;
        max-width: 240px;
      }
      img {
        width: 100%;
      }
    }
    .tel_m{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack:justify;
      -ms-flex-pack:justify;
      justify-content:space-between;
      @include respond-to(ipad){
        display: block;
      }
      a.phone{
        display: flex;
        align-items: center;
        @include respond-to(ipad){
          display: none;
        }
        .icon{
          font-size: 0;
        }
        .text{
          color: #3e4045;
        }
        img{
          max-width: 15px;
          @include respond-to(ipad){
            max-width: 100%;
          }
        }
      }
    }
    .info-logo {
      text-align: center;
      padding: 8px 15px;
      //width: 100%;
      height: auto;
      border-radius: 40px;
      //background-color: #0088dc;
      text-transform: uppercase;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      font-size: 14px;
      //margin: 0 10px;
      transition: all 200ms ease-in-out;
      font-weight: 400;
      color: #3b3d43;
      @include respond-to(ipad){
        text-align: left;
        padding: 10px 20px;
        font-size: 12px;
        max-width: none;
        width: auto;
        /*&:hover{
          -webkit-box-shadow: 0px 0px 21px 0px rgba(0,136,220,1);
          -moz-box-shadow: 0px 0px 21px 0px rgba(0,136,220,1);
          box-shadow: 0px 0px 21px 0px rgba(0,136,220,1);
        }*/
      }
      @include respond-to(desktop){
        font-size: 16px;
        max-width: 230px;
        width: 100%;
      }
    }
    .search-panel {
      min-width: 280px;
      //width: 400px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin: 5px;
      .search-text {
        width: 100%;
        height: 40px;
        padding-left: 20px;
        border: 1px solid #cacdd7;
        border-right: none;
        border-radius: 20px 0 0 20px;
        background-color: #fff;
        font-family: Verdana, sans-serif;
        color: #3b4756;
        font-size: 14pt;
        outline: none;
        @include respond-to(ipad){
          background-color: #eee;
        }
      }
      input[type="text"]{
        &:focus {
          box-shadow: inset 0 0 5px #d4d8da;
        }
      }
      .search-btn {
        position: relative;
        width: 22px;
        height: 20px;
        border: 1px solid #cacdd7;
        border-left: none;
        border-radius: 0 20px 20px 0;
        background-color: #fff;
        box-sizing: content-box;
        padding: 9px;
        @include respond-to(ipad){
          background-color: #eee;
        }
        img {
          width: 100%;
        }
        &:hover {
          cursor: pointer;
        }
        &:active {
          background-color: #e0e9ed;
        }
        input[type=search]{
          cursor: pointer;
          opacity: 0;
          width: 100%;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
        }
      }
    }
    .purchase-info {
      display: none;
      @include respond-to(ipad){
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
      }
      .icon {
        width: 50px;
        height: 50px;
        display: block;
        border-radius: 50%;
        position: relative;
        //height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        background-color: #eee;
        @include respond-to(desktop){
          padding-bottom: 0;
        }
        img, svg {
          width: 100%;
          max-width: 100%;
        }
        &.basket{
          img{
            width: auto;
          }
          svg{
            -webkit-transition: all 200ms ease-in-out;
            -moz-transition: all 200ms ease-in-out;
            -ms-transition: all 200ms ease-in-out;
            -o-transition: all 200ms ease-in-out;
            transition: all 200ms ease-in-out;
          }
          &.active{
            svg{
              path{
                fill: #0086d8;
              }
            }
            &:after{
              content: '';
              position: absolute;
              bottom: 0;
              left: 50%;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 8px 8px 8px;
              border-color: transparent transparent #093954 transparent;
              -webkit-transform: translate(-50%, 0);
              -moz-transform: translate(-50%, 0);
              -ms-transform: translate(-50%, 0);
              -o-transform: translate(-50%, 0);
              transform: translate(-50%, 0);
            }
          }
          &:hover{
            svg{
              path{
                fill: #0086d8;
              }
            }
          }
        }
      }
      .items {
        display: none;
        @include respond-to(ipad){
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
        }
      }
      .item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 0 10px;
        color: #212e40;
        font-family: Verdana, sans-serif;
        .count {
          color: #006ca8;
          margin-left: 2px;
        }
      }
    }
  }
}
.menu {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #0a537b;
  //background-color: #006ca8;
  //background-image: linear-gradient(to bottom, #006ca8, #1e79ac, #006ca8);
  border-bottom: 3px solid #084160;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .menu-icon {
    width: 30px;
    height: 30px;
    display: none;
    img {
      height: 100%;
    }
    &:hover {
      cursor: pointer;
    }
  }
  input[type=checkbox] {
    position: absolute;
    opacity: 0;
  }
  .offers {
    width: 120px;
    height: 45px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    &:hover {
      &:after{
        content: "";
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #e2b620;
      }
    }
    .icon {
      width: 28px;
      height: 28px;
      background-image: url(../img/icons/percentage.svg);
    }
    .text {
      font-family: Verdana, sans-serif;
      font-size: 10pt;
      text-transform: uppercase;
      color: #fbce34;
    }
  }
  .delivery-opts {
    width: 220px;
    height: 45px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Verdana, sans-serif;
    font-size: 10pt;
    text-align: center;
    text-transform: uppercase;
    background-color: #fbd03d;
    //background-image: linear-gradient(to bottom, #fbce34, #fcd75b, #fbce34);
    color: #026da8;
    &:after {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: #e2b620;
    }
    &:hover {
      &:after{
        background-color: #0a537b;
      }
    }
  }
  .menu-items {
    height: 45px;
    flex-basis: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    .item {
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0 5px;
      text-transform: uppercase;
      color: #fff;
      font-family: Verdana, sans-serif;
      font-size: 10pt;
      text-align: center;
      &:hover {
        color: #fbce34;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          bottom: -3px;
          left: 0;
          width: 100%;
          height: 3px;
          background-color: #e2b620;
        }
      }
    }
  }
}