html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, main {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}
html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  min-height: 100%;
  font-size: 10px;
}
body {
  font-weight: 300;
  font-size: 1.4rem;
  color: $color_black;
  line-height: 1.5;
  min-height: 100%;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}
ol, ul, li {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a{
  text-decoration: none;
  outline: none;
  &:active{
    text-decoration: none;
    outline: none;
  }
  &:visited{
    text-decoration: none;
    outline: none;
  }
  &:focus{
    text-decoration: none;
    outline: none;
  }
  &:hover{
    text-decoration: none;
    outline: none;
  }
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  &:focus{
    outline: none;
  }
}
html input[type="button"],
input[type="reset"],
input[type="submit"]{
  -webkit-appearance: button;
  cursor: pointer;
}