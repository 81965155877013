/******************************************************/
/*                        Footer                      */
/******************************************************/
.footer {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #3e3f45;
  .container {
    margin: 30px 0 40px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .categories {
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    .column {
      width: 33.3%;
      padding: 15px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      @include respond-to(ipad){}
      .item {
        color: #fefefe;
        text-transform: uppercase;
        font-family: Verdana, sans-serif;
        font-size: 12px;
        letter-spacing: 1px;
        &:not(:last-child) {
          margin-bottom: 13px;
        }
      }
    }
  }
  .contacts_info_m{
    margin: 0 0 15px 0;
    .contacts{
      &.column{
        text-align: center;
        font-size: 16px;
        @include respond-to(ipad){
          display: none;
        }
        .item{
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          text-align: center;
          margin: 0 0 10px 0;
        }
        .text{
          color: #fff;
          font-size: 18px;
        }
      }
    }
  }
  .information {
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    .column {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .contacts{
      display: none;
      @include respond-to(ipad){
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
    }
    .visual {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      @include respond-to(ipad){
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
      }
      .logo {
        max-width: 160px;
        width: 100%;
        padding: 0 15px;
        @include respond-to(ipad){
          padding: 0;
        }
      }
      .socials {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 0 15px;
        @include respond-to(ipad){
          padding: 0;
        }
        .item {
          width: 27px;
          height: 27px;
          border-radius: 50%;
          &:hover {
            cursor: pointer;
            box-shadow: 0 0 2px #77a4bd;
          }
          &:not(:first-child) {
            margin-left: 15px;
          }
        }
      }
    }
  }
  .contacts {
    .item{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 25px;
      .icon {
        width: 15px;
        height: 15px;
        margin-right: 15px;
        img {
          height: 100%;
        }
      }
      .text {
        color: #fff;
        font-family: Verdana, sans-serif;
        font-size: 12px;
      }
    }
  }
}
.footer-panel {
  width: 100%;
  background-color: #2d2e31;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  .container {
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    .copyright {
      font-family: Verdana, sans-serif;
      font-size: 10pt;
      color: #c3c9ce;
    }
  }
}